import {useTranslation} from "react-i18next";
import {useAuth} from "./hooks/use-auth";
import {useHistory} from "react-router";
import {
    AlertButton,
    IonAlert,
    IonCol,
    IonFab,
    IonFabButton,
    IonGrid,
    IonIcon,
    IonRow,
    useIonAlert,
    useIonRouter
} from "@ionic/react";
import useStore from "./StateStore";
import React, {useEffect, useRef, useState} from "react";
import {checkmark, close, pricetag} from "ionicons/icons";
// @ts-ignore
import SignaturePad from 'react-signature-pad-wrapper';
import {OverlayEventDetail} from "@ionic/react/dist/types/components/react-component-lib/interfaces";

export function FaraSignature(props: { onClick: (signaturePad: any, comment: string) => void , enableComment: boolean}) {
    const {t} = useTranslation();
    const history = useHistory()
    const [presentAlert] = useIonAlert();

    const [comment, setComment] = useState("");

    const [canSend, setCanSend] = useState(false);

    const [signaturePad, setSignaturePad] = React.useState<SignaturePad>();
    const signaturePadRef = React.useCallback((signaturePad: SignaturePad) => {
        setSignaturePad(signaturePad);
    }, []);

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    })

    useEffect(() => {
        if (signaturePad) {
            signaturePad.instance.addEventListener("endStroke", () => {
                setCanSend(true);
            }, { once: true });
        }
    }, [signaturePad]);

    return <>
        <IonGrid>
            <IonRow className=" ion-align-items-stretch">
                <IonCol className="ion-no-margin">
                    <div className="signatureborder">
                        <SignaturePad ref={signaturePadRef}
                                      options={{backgroundColor: "rgb(255,255,255)"}}/>
                    </div>
                </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center">
                <IonCol className="ion-text-center">
                    <h1 className="">
                        ↑
                        <p>
                            {t('pleaseSignHere')}
                        </p>
                    </h1>
                </IonCol>
            </IonRow>
            <IonRow className=" ion-align-items-stretch">
                <IonCol className="ion-no-margin ion-center">

                </IonCol>
            </IonRow>
        </IonGrid>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => history.goBack()}>
                <IonIcon icon={close}/>
            </IonFabButton>
        </IonFab>
        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={!props.enableComment}>
            <IonFabButton onClick={() =>
                presentAlert({
                    header: 'Kommentar',
                    buttons: ['OK'],
                    onDidDismiss: (e: CustomEvent) => setComment(e.detail.data.values[0]),
                    inputs: [
                        {
                            type: 'textarea',
                            value: comment
                        },
                    ],
                })
            } color={"medium"}>
                <IonIcon icon={pricetag}/>
            </IonFabButton>
        </IonFab>
        <IonFab vertical="bottom" horizontal="start" slot="fixed">
            <IonFabButton color={"success"} disabled={!canSend} onClick={() => props.onClick(signaturePad, comment)}>
                <IonIcon icon={checkmark}/>
            </IonFabButton>
        </IonFab>
    </>;
}

export function dataURLtoBlob(dataurl: string) {
    // @ts-ignore
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
}