import {IonCol, IonGrid, IonImg, IonRow} from "@ionic/react";
import React from "react";

const HomescreenLogo: React.FC = () => {
    return <IonGrid>
        <IonRow>
            <IonCol>
                <IonImg src={"assets/logo.svg"} class="ion-margin-horizontal"
                        style={{width: "70%", margin: "auto"}}/>
            </IonCol>
        </IonRow>
        <IonRow className="ion-align-items-center homescreenbackground">
            <IonCol className="ion-no-padding">
                <IonImg src={"assets/home_hero_bewerber_w.png"} class="ion-margin-horizontal"
                        style={{height: "170px"}}/>
            </IonCol>
            <IonCol className="ion-no-padding">
                <IonImg src={"assets/home_hero_bewerber_m.png"} class="ion-margin-horizontal"
                        style={{height: "170px"}}/>
            </IonCol>
        </IonRow>
    </IonGrid>;
}

export default HomescreenLogo;