import {
    IonBackButton,
    IonBadge,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSpinner,
    IonTitle,
    IonToolbar,
    useIonViewWillEnter,
    IonSegment,
    IonSegmentButton,
    IonFabButton,
    IonFab,
    IonIcon,
    IonButton,
    IonItemGroup,
    IonItemDivider, useIonAlert
} from '@ionic/react';
import React, {useEffect} from 'react';
import '../Home.css';
import useStore, {FaraState, TimeTrackType} from '../../StateStore'
import useFetch, {CachePolicies, CustomOptions, IncomingOptions} from "use-http";
import {useTranslation} from "react-i18next";
import FaraLoading from "../../components/FaraLoading";
import FaraError from "../../components/FaraError";
import useLocalStorage from "../../hooks/use-localstorage";
import {add, checkmark, pencil, save} from 'ionicons/icons';
import {useAuth} from "../../hooks/use-auth";
import {RouteComponentProps, useHistory} from "react-router";
import moment from "moment";
import 'moment/locale/de';
import {dataURLtoBlob, FaraSignature} from "../../FaraSignature";

const CompleteTimeSheet: React.FC<RouteComponentProps<{ id: string }>> = ({match}) => {
    const {t} = useTranslation();
    const auth = useAuth();
    const store = useStore()
    const history = useHistory()
    const [present] = useIonAlert();

    useIonViewWillEnter(() => {
        store.fetchTimeSheets(auth)
    });

    function saveSig(signaturePad: any) {
        // @ts-ignore
        let data = signaturePad.toDataURL();

        const formData = new FormData();
        formData.append("file", dataURLtoBlob(data))

        fetch(`${process.env.REACT_APP_API_URL}/timetrack/${match.params.id}`, { // Your POST endpoint
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${auth.login}`,
            },
            body: formData
        }).then(data => {
                if (data.ok) {
                    data.json().then(() => {
                        store.fetchTimeSheets(auth)
                        history.push('/home')
                    })
                } else if(data.status === 400) {
                    present({
                        header: t('alert'),
                        message: t('workTimeLawAlert'),
                        buttons: [
                            {text: 'Ok'},
                        ],
                        onDidDismiss: (e) => history.goBack(),
                    })
                } else {
                    throw data
                }
            }
        ).catch(
            error => console.log(error)
        );
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text={t('back')} defaultHref="home"/>
                    </IonButtons>
                    <IonTitle>{t('timetrack')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <FaraSignature onClick={saveSig} enableComment={false}/>
            </IonContent>
        </IonPage>
    );
};

export default CompleteTimeSheet;
