import React, {useEffect} from 'react';
import {Redirect, Route, RouteComponentProps, withRouter} from 'react-router-dom';
import {
    IonApp, IonBadge,
    IonContent,
    IonHeader, IonItem, IonList,
    IonMenu, IonMenuButton, IonMenuToggle, IonPage,
    IonRouterOutlet,
    IonSplitPane, IonText,
    IonTitle,
    IonToolbar, setupIonicReact, useIonRouter
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import { menuController } from "@ionic/core/components";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.scss'

import Documents from "./pages/Documents";
import ExploreContainer from "./components/ExploreContainer";
import DocumentsList from "./pages/DocumentsList";
import DocumentViewer from "./pages/DocumentViewer";
import Login from "./pages/Login";
import create from "zustand";
import {useAuth} from "./hooks/use-auth";
import DocumentSignature from "./pages/DocumentSignature";
import useStore from "./StateStore";
import Bewerber from "./pages/Bewerber";
import TimeTrackViewer from "./pages/timetrack/TimeTrackViewer";
import AddTimeTrack from "./pages/timetrack/AddTimeTrack";
import AddTimeSheet from "./pages/timetrack/AddTimeSheet";
import TimeSheetList from "./pages/timetrack/TimeSheetList";
import CompleteTimeSheet from "./pages/timetrack/CompleteTimeSheet";
import AppVersion from "./AppVersion";

setupIonicReact();

const App: React.FC<RouteComponentProps> = (props) => {
    const auth = useAuth()
    const router = useIonRouter()

    const store = useStore()
    let documents = store.employee?.documents;

    const closeMenu = async () => {
        await menuController.close();
    };

    return (
        <IonApp>
            <IonReactRouter>
                <IonContent>
                    <IonSplitPane contentId="main">
                        {/*--  the side menu  --*/}
                        <IonMenu side="start" menuId="first" contentId="main">
                            <IonHeader>
                                <IonToolbar>
                                    <IonTitle>Menu</IonTitle>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>
                                <IonList>
                                    <IonMenuToggle menu="start" autoHide={false} hidden={!auth.login}>
                                        <IonItem disabled={!store.hasRole("ROLE_TIMETRACK")} routerLink={"/timetrack"}>Zeiterfassung</IonItem>
                                    </IonMenuToggle>
                                    <IonMenuToggle menu="start" autoHide={false} hidden={!auth.login}>
                                        <IonItem routerLink={"/documents"} disabled={documents?.length === 0}>Dokumente</IonItem>
                                    </IonMenuToggle>

                                    <IonItem button routerLink={"/login"} onClick={() => {
                                        auth.signout()
                                        menuController.close("first")
                                    }}>Logout</IonItem>

                                    <AppVersion />

                                </IonList>
                            </IonContent>
                        </IonMenu>

                        {/*-- the main content --*/}
                        <IonPage id="main">
                            <IonRouterOutlet>
                                <Route path="/timetrack" exact={true} component={TimeSheetList}/>
                                <Route path="/timetrack/addsheet" exact={true} component={AddTimeSheet}/>
                                <Route path="/timetrack/add/:forid/:day/:startDate" component={AddTimeTrack}/>
                                <Route path="/timetrack/edit/:sheetid/:id/:startDate" component={AddTimeTrack}/>
                                <Route path="/timetrack/open/:id" component={TimeTrackViewer}/>
                                <Route path="/timetrack/complete/:id" component={CompleteTimeSheet}/>
                                <Route path="/documents/" exact={true} component={Documents}/>
                                <Route path="/documents/categorie/:cat" component={DocumentsList}/>
                                <Route path="/document/:id" component={DocumentViewer}/>
                                <Route path="/documentsign/:id/:who" component={DocumentSignature}/>
                                <Route path="/bewerber/:isBewerberTypeAktiv" component={Bewerber}/>
                                <Route path="/login/" component={Login}/>
                                <Route path="/logout/" component={Login}/>
                                <Route path="/home" render={props => auth.login ? <Home /> : <Login />
                                }/>
                                <Redirect exact from="/" to="/home"/>
                            </IonRouterOutlet>
                        </IonPage>
                    </IonSplitPane>
                </IonContent>
            </IonReactRouter>
        </IonApp>
    );
};

export default withRouter(App);
