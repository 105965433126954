import {
    IonButton,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonRow,
    IonText,
    IonToolbar,
    useIonViewWillEnter
} from '@ionic/react';
import React from 'react';
import './Home.css';
import {document, time} from 'ionicons/icons';
import useRequireAuth from "../hooks/use-require-auth";
import FaraLoading from "../components/FaraLoading";
import {useAuth} from "../hooks/use-auth";
import useStore from "../StateStore";
import {useTranslation} from "react-i18next";
import NewsCard from "./News";

const Home: React.FC = () => {
    const {t} = useTranslation();
    const auth = useRequireAuth();
    const auth2 = useAuth();

    const store = useStore()

    // useEffect(() => {
    //     console.log("effect in home" + auth2.login)
    //     store.fetchDocuments(auth2)
    // }, [store.fetchDocuments, auth2])

    useIonViewWillEnter(() => {
        store.fetchDocuments(auth2)
    });

    // useIonViewDidEnter(() => {
    //     console.log("did enter in home" + auth2.login)
    //     store.fetchDocuments(auth2)
    // });

    if (!auth) {
        return <FaraLoading/>;
    }

    let documents = store.employee?.documents;
    let isBewerber = store.hasRole("ROLE_BEWERBER")

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonMenuButton slot="start"/>
                </IonToolbar>
            </IonHeader>
            <IonContent color="favorite">
                <section className="ion-margin">
                    <IonCard>
                        <IonGrid className="ion-no-padding">
                            <IonRow className="ion-align-items-center homescreenbackground">
                                <IonCol className={"ion-padding-horizontal"} size={"6"}>
                                    <img className="equal-height" alt={"logo"} src={"assets/logo.svg"}/>
                                </IonCol>
                                <IonCol className={"ion-padding-horizontal"} size={"6"}>
                                    <img className="equal-height" alt={"logo"} src={"assets/Mensch2801b.svg"}/>
                                </IonCol>
                            </IonRow>
                            {/*<IonRow className=" ion-align-items-stretch">*/}
                            {/*    <IonCol className="ion-text-center">*/}
                            {/*        <IonText className=" blion-text-center">*/}
                            {/*            <h4>{store.employee?.niederlassung}</h4>*/}
                            {/*        </IonText>*/}
                            {/*    </IonCol>*/}
                            {/*</IonRow>*/}
                        </IonGrid>
                    </IonCard>

                </section>
                <section className="ion-margin">
                    <IonCard>
                        {!documents && <FaraLoading/>}
                        {documents && <IonGrid className="ion-margin-horizontal ion-margin-bottom">
                            <IonRow className=" ion-align-items-stretch">
                                <IonCol className="ion-text-center">
                                    <h2>
                                        <IonText
                                            className=" blion-text-center">{t('hello')} {store.employee?.vorname} {store.employee?.nachname}</IonText>
                                    </h2>
                                </IonCol>
                            </IonRow>

                            {isBewerber ?
                                <IonRow className=" ion-align-items-stretch">
                                    <IonCol className="ion-no-margin">
                                        <IonButton className="mainmenubtn" expand="block"
                                                   routerLink={`/bewerber/${isBewerber}`}>{t('applyNow')}</IonButton>
                                    </IonCol>
                                </IonRow>
                                :
                                <>
                                    <IonRow className=" ion-align-items-stretch">
                                        <IonCol className="ion-no-margin">
                                            <IonButton className="mainmenubtn "
                                                       disabled={!store.hasRole("ROLE_TIMETRACK")}
                                                       expand="block" color="farared"
                                                       routerLink={"/timetrack"}>{t('timetrack')}
                                                <IonIcon slot="start" icon={time}/>
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className=" ion-align-items-stretch">
                                        <IonCol className="ion-no-margin">
                                            <IonButton className="mainmenubtn" disabled={documents?.length === 0}
                                                       expand="block" color="farared"
                                                       routerLink={"/documents"}>{t('documents')}
                                                <IonIcon slot="start" icon={document}/>
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </>
                            }
                        </IonGrid>}
                    </IonCard>
                </section>

                {!isBewerber && <NewsCard/>}
            </IonContent>
        </IonPage>
    );
};

export default Home;
